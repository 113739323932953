.css-13cymwt-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    border: 0 ! important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 3px #00000029;
    opacity: 1;
    border-right: none !important;
}


.css-tj5bde-Svg {
    background-image: url('../../images/SearchIcon1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    line-height: 1;
    padding-left: 0px;
    color: white;
    stroke: white;
    stroke-width: 0;
}
.css-1jqq78o-placeholder {

    text-align: center;
    font-size: 23px;
    font: normal normal normal 23px/31px Segoe UI;
    letter-spacing: 0px;
    color: #B1B1B1;
    opacity: 1;
    text-align: start;
}
.css-1u9des2-indicatorSeparator {
    background:none !important;
}
.css-1dimb5e-singleValue{
    text-align: start !important;
}